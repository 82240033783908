import { graphql } from "gatsby";
import Insurance from "../templates/insurance";

const InsurancePage = ({
	data: {
		craft: { entry },
	},
}) => <Insurance entry={entry} />;

export const pageQuery = graphql`
	query InsurancePage {
		craft {
			entry(slug: "insurance") {
				... on CraftCMS_insurance_insurance_Entry {
					id
					metaTitle
					metaDescription
					metaShareImage {
						filename
						url
					}
					heroTitle
					heroText
					heroCtaFirst {
						element {
							slug
							uri
						}
						ariaLabel
						customText
						target
						text
						title
						type
						url
					}
					heroImage {
						width
						height
						url
						title
						imagerX(transform: "maxWidth-1920") {
							width
							height
							url
						}
						imagerXWp: imagerX(transform: "maxWidth-1920-wp") {
							width
							height
							url
						}
					}
					heroImageMobile {
						width
						height
						url
						imagerX(transform: "maxWidth-1920") {
							width
							height
							url
						}
						imagerXWp: imagerX(transform: "maxWidth-1920-wp") {
							width
							height
							url
						}
					}
					tabsTitle
					tabsItems {
						... on CraftCMS_tabsItems_tabsItem_BlockType {
							id
							tabsItemTitle
							tabsItemText
							tabsItemButton {
								element {
									slug
									uri
								}
								ariaLabel
								customText
								target
								text
								title
								type
								url
							}
						}
					}
					insuranceRatesTitle
					insuranceRatesBlocks {
						... on CraftCMS_insuranceRatesBlocks_insuranceRatesBlock_BlockType {
							id
							ratesBlockTitle
							ratesBlockFigure
							ratesBlockText
						}
					}
					insuranceBannerTitle
					insuranceBannerText
					insuranceBannerImage {
						width
						height
						url
						imagerX(transform: "maxWidth-1920") {
							width
							height
							url
						}
						imagerXWp: imagerX(transform: "maxWidth-1920-wp") {
							width
							height
							url
						}
					}
					insuranceRatingTableTitle
					insuranceRatingTableSmallPrint
					insuranceRatingTable {
						... on CraftCMS_insuranceRatingTable_insuranceRatingTableRow_BlockType {
							insuranceRatingTableRowCategory
							insuranceRatingTableRowSymbol
							insuranceRatingTableRowNotch
						}
					}
					faqsTitle
					faqsItems {
						... on CraftCMS_faqsItems_faqsItem_BlockType {
							id
							faqsItemTitle
							faqsItemText
						}
					}
					footerBannerTitle
					footerBannerText
					footerBannerButton {
						element {
							slug
							uri
						}
						url
						type
						title
						text
						target
						customText
					}
				}
			}
		}
	}
`;

export default InsurancePage;
